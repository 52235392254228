import '@babel/polyfill';
import _ from 'lodash';
import $ from 'jquery';
import '@fortawesome/fontawesome-pro/js/all';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/respimg/ls.respimg';
import FastClick from 'fastclick';
import 'popper.js';
import { Calendar } from 'fullcalendar';

import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';

import '../img/swisspga-logo.png';

import '../img/placeholder/placeholder.jpg';
import '../img/placeholder/placeholder_portrait.jpg';

document.addEventListener('DOMContentLoaded', () => {
    (async () => { // async function expression used as an IIFE
        const calendarEl = document.getElementById('bla');

        if (calendarEl) {
            const calendar = new Calendar(calendarEl, {
                height: 'auto',
                events: window.events,
            });

            calendar.render();
        }
    })();
});

//
/** @var module */
if (module.hot) {
    module.hot.accept();
}

// App main
const main = async () => {
    // Import our CSS
    const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.scss');

    window.$ = $;

    document.documentElement.classList.remove('no-js');
    FastClick.attach(document.body);

    const userbar = document.getElementById('userbar');
    const userbarCollapse = document.getElementById('userbarCollapse');
    const ads = document.getElementById('ads');

    if (navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)) || (typeof $.browser !== "undefined" && $.browser.msie == 1)) {
        if (window.innerWidth > 992) {
            document.getElementById('navbarCollapse').setAttribute('style', 'display:inline-block !important');
        } else {
            document.getElementById('navbarCollapse').setAttribute('style', '');
        }

        window.addEventListener('resize', _.debounce(() => {
            if (window.innerWidth > 992) {
                document.getElementById('navbarCollapse').setAttribute('style', 'display:inline-block !important');
            } else {
                document.getElementById('navbarCollapse').setAttribute('style', '');
            }
        }, 500));
    }

    function alignAds() {
        const userbarBottomPos = userbarCollapse.getBoundingClientRect().bottom;
        const adsTopPos = ads.getBoundingClientRect().top;
        const difference = Math.abs(userbarBottomPos - adsTopPos);

        if (difference > 0) {
            ads.style.marginTop = `${Math.ceil(difference) + 30}px`
        }
    }

    if (ads && userbar.classList.contains('userbar_lock')) {
        window.addEventListener('resize', _.debounce(alignAds, 500));
        setTimeout(() => alignAds(), 500);
    }

    Array.from(document.querySelectorAll('input.radio[type="checkbox"]'))
        .forEach(checkbox => checkbox.addEventListener('click', () => {
            if (checkbox.checked) {
                Array.from(document.querySelectorAll(`input[type="checkbox"][name="${checkbox.name}"]`))
                    .forEach(element => {
                        if (element != checkbox) {
                            element.checked = false;
                            element.parentElement.classList.remove('font-weight-bolder');
                        }
                    });

                checkbox.parentElement.classList.add('font-weight-bolder');
            } else {
                checkbox.parentElement.classList.remove('font-weight-bolder');
            }
        }));

    /**
     * Initalize tablesorter
     */
    Array.from(document.querySelectorAll('.tablesorter'))
        .forEach((table) => {
            require('tablesorter'); // eslint-disable-line global-require

            $(table).tablesorter({
                widgets: ['zebra', 'filter'],
                widgetOptions: {
                    filter_columnFilters: true,
                    filter_placeholder: {
                        search: 'Search...',
                    },
                    filter_saveFilters: false,
                },
            });
        });

    /**
     * Typeahead for form select fields.
     */
    Array.from(document.querySelectorAll('form select[data-inputs]'))
        .forEach((select) => {
            const input = document.querySelector(select.dataset.inputs);
            const originalOptions = Array.from(select.options);

            const findMatches = (search, options) => options.filter((option) => {
                const regex = new RegExp(search, 'gi');
                return option.text.match(regex);
            });

            const filterOptions = () => {
                const inputValue = input.value || '-{2}$';
                const matchArray = findMatches(`^(${inputValue}|-{2}$)`,
                    originalOptions);

                originalOptions.forEach((option) => {
                    console.log(option.parentNode);

                    if (option.parentNode) {
                        option.parentNode.removeChild(option);
                    }

                    option.selected = false; // eslint-disable-line no-param-reassign
                });

                Array.from(matchArray)
                    .forEach(element => select.appendChild(element));
            };

            filterOptions();
            input.addEventListener('change', filterOptions);

            select.value = select.querySelector('[selected]').value || ''; // eslint-disable-line no-param-reassign
        });

    /**
     *  Make table rows linkable.
     */
    Array.from(document.querySelectorAll('tr[data-href]'))
        .forEach(element => element
            .addEventListener('click', (event) => {
                const href = element.getAttribute('data-href');
                const target = element.getAttribute('data-target') || '_self';

                if (href) {
                    const normalizedTarget = (event.ctrlKey || event.metaKey)
                        ? '_blank'
                        : target;

                    window.open(href, normalizedTarget);
                }
            }));

    /**
     * Make entire fieldset required if one is filled in.
     */
    Array.from(document.querySelectorAll('fieldset[data-form-required]'))
        .forEach((element) => {
            const fields = Array.from(element
                .querySelectorAll('input[type="text"], textarea, select'))
                .filter(field => field.getAttribute('data-form-required') !== 'false');

            const getFieldsWithValues = () => fields
                .filter(field => field.value !== '');

            const setFieldRequire = (require = true) => fields.map((field) => {
                const isRequired = require
                    ? field.setAttribute('required', true)
                    : field.removeAttribute('required');

                return isRequired;
            });

            fields.forEach((fieldElement) => {
                setFieldRequire(getFieldsWithValues().length > 0);
                fieldElement.addEventListener('change', () => setFieldRequire(getFieldsWithValues().length > 0));
            });
        });

    /**
     * Clone ads to mobile placeholders
     */
    const cloneMobileAds = () => {
        if (window.innerWidth < 768) {
            Array.from(document.querySelectorAll('#ads > [data-target]'))
                .forEach((element) => {
                    const target = document.querySelector(element.getAttribute('data-target'));
                    const clone = element.cloneNode(true);
                    const cloneImg = clone.querySelector('img');

                    element.removeAttribute('data-target');
                    clone.removeAttribute('data-target');
                    clone.classList.add('ad_mobile');

                    if (cloneImg) {
                        cloneImg.classList.remove('lazyloaded');
                        cloneImg.classList.add('lazyload');
                    }

                    if (target) {
                        target.appendChild(clone);
                    }
                });
        }
    };

    cloneMobileAds();
    window.addEventListener('resize', _.debounce(() => {
        cloneMobileAds();
    }, 500));

    $('#navbarCollapse').on('shown.bs.collapse hide.bs.collapse', (event) => {
        if (event.currentTarget !== event.target) {
            return;
        }

        document.documentElement.classList.toggle('nav-in');
    });

    $('.collapse').on('hidden.bs.collapse', function () {
        $(this).find('.collapse').collapse('hide');
    });

    $('[data-toggle="tooltip"]').tooltip();
};

// Execute async function
main().then(() => {
});
